module.exports = {
  // ---------------------  sidebar  -------------------------
  'sidebar.catalogManagement': 'Catalog Management',
  'sidebar.benchmarking': 'Benchmarking',
  'sidebar.aggSheetRelations': 'Agg. Sheet Relations',
  'sidebar.ticketManagement': 'Ticket Management',
  'sidebar.partner': 'Partner Info',
  'sidebar.userManagement': 'CMS User Management',
  'sidebar.elkasoUsers': 'Kaso Users',
  'sidebar.suppliers': 'Suppliers',
  'sidebar.restaurants': 'Restaurants',
  'sidebar.invitationRequests': 'Invitations',
  'sidebar.units': 'Units',
  'sidebar.Branches': 'Restaurant Branches',
  'sidebar.restaurant.users': "Restaurant's suppliers",
  'sidebar.supplier.users': 'Users',
  'sidebar.userProfile': 'Profile',
  'sidebar.offers': 'Offers',
  'sidebar.fullScreen': 'Full Screen',
  'sidebar.darkMode': 'Dark Mode',
  'sidebar.lightMode': 'Light Mode',
  'sidebar.close': 'Close',
  'sidebar.open': 'Open',
  'sidebar.services': 'Services',
  'sidebar.serviceCategories': 'Service Categories',
  'sidebar.serviceOffers': 'Offers',
  'sidebar.partnerCategory': 'Partner Categories',
  'sidebar.customers': 'Customers',
  'sidebar.employee': 'Users',
  'sidebar.geoFencing': 'Zones',
  'sidebar.orders': 'Orders',
  'sidebar.reports': 'Reports',
  'sidebar.reports.partnerPerformance': 'Partner Performance',
  'sidebar.reports.customerPerformance': 'Customer Performance',
  'sidebar.reports.financialReport': 'Financial',
  'sidebar.reports.joining': 'Customers Joining',
  'sidebar.reports.employees': 'User Performance',
  'sidebar.reports.partnerDashboard': 'Partner Dashboard',
  'sidebar.settings': 'Settings',
  'sidebar.help': 'Help',
  'sidebar.OTP': 'OTP Callbacks',
  'sidebar.aboutUS': 'About Us',
  'sidebar.statistics': 'Statistics',
  'sidebar.promotions': 'Promotions',
  'sidebar.categories': 'Categories',
  'sidebar.permissions': 'Preferences',
  'sidebar.otp': 'OTP',
  'sidebar.whatsNumbers': 'WhatsApp Numbers',
  'sidebar.countries': 'Countries',
  'sidebar.restaurantTransfers': 'Restaurant Transfers',
  'sidebar.posIntegrations': 'POS Integrations',
  'sidebar.products': 'Products',

  // ---------------------  header  -------------------------
  'title.languages': 'Language',

  // ---------------------  Pagination  -------------------------
  'pagination.count': '{from} - {to} of {count} rows',
  'global.tablePagination.label': 'Rows per page',
  'global.table.display': 'Display {value} Rows from {total}',

  // ---------------------  widgets  -------------------------

  'widgets.profile': 'Profile',
  'widgets.moreAction': 'More',
  'widgets.logOut': 'Logout',
  'widgets.appointment': 'Appointments',
  'widgets.recentAlerts': 'Recent Alerts',
  'widgets.offerHistory': 'Offer History',
  'widgets.viewAction': 'OTP Request',
  'widget.copy': 'Copy',
  'widget.paste': 'Paste',
  'widget.copyAll': 'Copy Opening Hours',
  'widget.pasteAll': 'Paste Opening Hours',
  'widget.resetAll': 'Reset Opening Hours',
  'notification.new': 'New',
  'notification.old': 'Old',
  'notification.countOfNotification': 'Notifications Count',
  'notification.user.login': 'User Logged in Successfully',
  'notification.user.logout': 'User Logged out Successfully',
  'notification.inactive_user': 'This account is inactive! Please activate it and try again',
  'notification.Unauthorized': 'You are not authorized, please try to login again',
  'notification.invalid_credentials': 'You are not authorized, please try to login again',
  'notification.exceeded_the_number_of_requests': 'Too many failed attempts, please retry later',
  'notifications.success': 'Success !',
  'notifications.error': 'Error !',

  // ---------------------  Actions  -------------------------

  'global.actions': 'Actions',
  'global.actions.open': 'Open',
  'global.actions.close': 'Close',
  'global.actions.create': 'Create',
  'global.actions.delete': 'Delete',
  'global.actions.update': 'Update',
  'global.actions.selectAll': 'Select All',
  'global.actions.attach': 'Attach Supplier',
  'global.actions.view': 'View',
  'global.actions.viewOrders': 'View Orders',
  'global.actions.viewLocations': 'View Locations',
  'global.actions.generate': 'Generate',
  'global.actions.Filter': 'Filter',
  'global.actions.showFilters': 'Show Filter',
  'global.actions.save': 'Save',
  'global.action.showAll': 'Show more',
  'widgets.editAction': 'Edit',
  'widgets.deleteAction': 'Delete',
  'widgets.reply': 'Reply',
  'components.submit': 'Submit',
  'components.set.password': 'Set Password',
  'components.change.password': 'Change password',
  'login.actions.signin': 'Log in',
  'error.required': 'These fields are required',
  'login.actions.reset': 'Reset password',
  'login.actions.back.homepage': 'Back To Home Page',
  'login.actions.back': 'Back',
  'login.actions.forgetPassword': 'Did you forget your password?',
  'login.backLogin': 'Back To Login Page',
  'login.info': 'Please enter your account information',
  'login.forgetPassword': 'Forget Password',
  'login.forgetPassword.info': 'Please enter your email address to reset your password',
  'login.setPassword': 'Set Password',
  'login.setPassword.info': 'Please enter the new password',
  'global.title.set.password': 'Reset Password',
  'global.details.set.password':
    'An email with instructions for resetting your password has been sent to your email address',
  'global.title.token.expired': 'Oopsss! Token Expired',
  'global.title.session.expired': 'Oopsss! Session Expired',
  'global.details.token.expired': "Your token isn't valid, please try again",
  'global.details.session.expired': 'Your session expired, please tell management to reactivate your account',
  'global.title.access.denied': 'Oopsss! Access Denied',
  'button.cancel': 'Cancel',
  'button.save': 'Save',
  'button.add': 'Add',
  'button.edit': 'Edit',
  'button.yes': 'Yes',

  'form.basic.info': 'Basic Info',
  'form.other.info': 'Information',
  'form.activation': 'Partner Activation',
  'form.opening.hours': 'Opening Hours',
  'form.opening.hours.days': 'Days',
  'form.opening.hours.open': 'Open',
  'form.opening.hours.close': 'Close',
  'form.opening.hours.workingDay': 'Working Days',
  'form.opening.hours.twoShifts': 'Two Shifts',
  'form.opening.hours.errors.400': 'You must Select a Start and End Time',
  'form.opening.hours.errors.401': 'You must select an End Time ',

  'form.map.info': 'Partner Location',
  'map.search': 'Search for an address',

  'global.day.sun': 'Sunday',
  'global.day.sat': 'Saturday',
  'global.day.mon': 'Monday',
  'global.day.wed': 'Wednesday',
  'global.day.tue': 'Tuesday',
  'global.day.thu': 'Thursday',
  'global.day.fri': 'Friday',

  // ---------------------  Global Messages  -------------------------
  'global.options.noMatched': 'No available options',
  'global.project.name': 'Elkaso',
  'global.noData': 'No Data',
  'global.empty': 'No Data',
  'global.error.somethingWentWrong': 'Oops,, something went wrong!!',
  'global.true': 'Yes',
  'global.false': 'No',
  'global.activate.true': 'Active',
  'global.deactivate.false': 'Inactive',
  'global.deactivate.null': 'Inactive',
  'global.success.message.new': 'The record has been added successfully',
  'global.success.message.edit': 'The record has been edited successfully',
  'global.success.message.reassign': 'The record has been reassigned successfully',
  'global.success.message.delete': 'The record has been deleted successfully',
  'global.error.oldPassword': 'Old password is incorrect',
  'global.errors.notUpload': 'An error occurred! You cannot upload this image',
  'global.errors.invalid_register_token': 'Invalid token!',
  'global.errors.expired_register_token': 'Expired token!',

  'global.select.none': 'None',
  'global.select.noItems': 'No more items to add',
  'global.success.message.cancel': 'Your request has been canceled successfully',
  'global.createdAt': 'Creation Date',
  'global.image': 'Image',
  'global.actions.expand': 'Expand',
  'global.title.page.notfound': 'Oopsss! Page Not Found',
  'global.details.access.denied': "Looks like you have navigated too far from your Space, we can't help you here",
  'global.button.ok': 'Ok',
  'global.button.gotIt': 'Got It!',
  'global.crop': 'Crop',
  'global.crop.image': 'Crop image',
  'button.anotherImage': 'Okay',
  'global.empty.list': 'List is empty!',
  'global.loading': 'Loading',

  // ---------------------  Errors  -------------------------

  'global.errors.101': 'You are not authorized, please try to login again later.',
  'global.errors.102': 'The current version is too old',
  'global.errors.103': 'Sorry, an error occurred. Please try again.',
  'global.errors.104': "Sorry, you don't have permission to proceed",
  'global.errors.105': "Sorry, the Page you requested wasn't found.",
  'global.errors.107': "Sorry, you don't have permission to proceed",
  'global.errors.108': 'Sorry, an error occurred. Please try again.',
  'global.errors.802': 'Code already exists',
  'global.errors.500': 'Unknown error occurred, please try again',
  'global.errors.name.unique': 'Name is not unique',
  'global.errors.coordinates.unique': 'Map coordinates are not unique',
  'global.errors.required': 'This field is required',
  'global.errors.differenceTime': 'Please check the Opening Hours',
  'global.errors.wrongTime': 'Incorrect Time',
  'global.errors.email.notValid': 'E-mail is not valid',
  'global.errors.countryCode.notValid': 'Invalid country code!',
  'global.errors.phone_number_countryCode.unique': 'Invalid country code!',
  'global.errors.email.unique': 'E-mail is already exist!',
  'global.errors.phone_number.unique': 'The phone number already exists!',
  'global.errors.phone_number_already_exists.unique': 'The phone number already exists!',
  'global.errors.phoneNumber.notValid': 'Phone Number is not valid',
  'global.errors.phone.notValid': 'Phone Number is not valid',
  'global.errors.roadAssistantWithWarranty.notValid': 'Road Assistant With Warranty Number is not valid',
  'global.errors.roadAssistantWithoutWarranty.notValid': 'Road Assistant Without Warranty Number is not valid',
  'global.errors.whatsApp.notValid': 'WhatsApp Number is not valid',
  'global.errors.duration.notValid': 'Enter a duration which is a mutltiple of 30 minutes',
  'global.errors.duration.negative': 'Please enter a valid duration',
  'global.errors.fill': 'All mandatory fields must be filled',
  'global.errors.differenceShifts': 'End Time should be later than the Start Time',
  'global.errors.differenceTwoShifts': 'Start Time of Two Shifts must not exceed the End Time of a working day',
  'global.errors.not_found': 'E-mail has not been found',
  'global.errors.date': 'Invalid Date',
  'global.error.negative': 'Value must be positive and bigger than 0!',

  // ---------------------  Users Management  -------------------------
  'users.form.new.title': 'New User',
  'users.form.edit.title': 'Edit User',
  'users.form.view.title': 'View user',
  'users.form.edit.success': 'User has been edited successfully',
  'user.name': 'User Name',
  'user.email': 'E-mail',
  'user.phoneNumber': 'Phone Number',
  'user.landLineNumber': 'Land Line Number',
  'user.active': 'Activation',
  'user.role': 'Role',
  'user.roles': 'Roles',
  'user.countries': 'Countries',
  'user.button.new': 'Add user',
  'user.button.edit': 'Edit user',
  'user.button.clear': 'Clear filter',
  'user.createdAt': 'Creation Date',
  'user.password': 'Password',
  'user.oldPassword': 'Old Password',
  'user.newPassword': 'New Password',
  'user.passwordConfirmation': 'Password confirmation',
  'user.password.confirmation': 'Password does not match',
  'user.password.validate': 'Password must contain at least one number, one uppercase letter and at least 8 characters',
  'user.password.length': 'Password must be at least 8 characters',
  'user.success.change.password': 'Password has been changed successfully',
  'button.activateButton': 'Activate',
  'button.deactivateButton': 'Deactivate',
  'button.cancelButton': 'Cancel',
  'user.button.update': 'Update profile',
  'user.isVerify': 'E-Verification',
  'users.verify.true': 'Verified',
  'users.verify.false': 'Unverified',
  'dialog.user.hasVerifiedEmail.false': 'Unverify email of user!',
  'dialog.message.user.hasVerifiedEmail.false': 'Are you sure you want to unverify this email?',
  'dialog.user.hasVerifiedEmail.true': 'Verify email of user!',
  'dialog.message.user.hasVerifiedEmail.true': 'Are you sure you want to verify this email?',
  'button.users.verify': 'Verify',
  'button.users.unverify': 'Unverify',
  // ---------------------  Address  -------------------------
  'address.label': 'Nickname',
  'address.lat': 'Lat',
  'address.long': 'Long',
  'address.fullLine': 'Full Line',
  'address.map': 'Address on Map',

  // ---------------------  Dialog Messages  -------------------------
  'dialog.user.isActive.false': 'Deactivate user!',
  'dialog.user.isActive.true': 'Activate user!',
  'dialog.offer.isActive.false': 'Deactivate promotion!',
  'dialog.offer.isActive.true': 'Activate promotion!',
  'dialog.isActive': 'Activate / deactivate ',
  'dialog.message.user.isActive.false': 'Are you sure you want to deactivate this user?',
  'dialog.message.user.isActive.true': 'Are you sure you want to activate this user?',
  'dialog.extraInfo.user.isActive.false': "User won't be able to login until the user is activated again",
  'dialog.extraInfo.user.isActive.true': 'User will be able to login after activation',
  'dialog.message.offer.isActive.false': 'Are you sure you want to deactivate this promotion?',
  'dialog.message.offer.isActive.true': 'Are you sure you want to activate this promotion?',
  'dialog.product.status.inactive': 'Deactivate product!',
  'dialog.product.status.active': 'Activate product!',
  'dialog.message.product.status.inactive': 'Are you sure you want to deactivate this product?',
  'dialog.message.product.status.active': 'Are you sure you want to activate this product?',
  'dialog.unit.isActive.false': 'Deactivate unit!',
  'dialog.unit.isActive.true': 'Activate unit!',
  'dialog.message.unit.isActive.false': 'Are you sure you want to deactivate this unit?',
  'dialog.message.unit.isActive.true': 'Are you sure you want to activate this unit?',
  'dialog.restaurant.user.delete': 'Delete Restaurant User',
  'dialog.message.restaurant.user.delete': 'Are you sure you want to delete this user?',
  'dialog.products.delete': 'Delete Products',
  'dialog.message.products.delete': 'Are you sure you want to delete {productsCount} product?',
  'dialog.restaurant.supplier.detach': 'Detach Supplier',
  'dialog.message.restaurant.supplier.detach': 'Are you sure you want to detach {supplierName}',
  'dialog.error': 'Error!',
  'dialog.message.errorExt': 'You can only upload an image by using these extensions jpg, jpeg and png',
  'dialog.createOffer': 'Create promotion!',
  'dialog.updateOffer': 'Update promotion!',
  'dialog.message.createMsg': 'Are you sure you want to create this promotion?',
  'dialog.message.updateMsg': 'Are you sure you want to update this promotion?',
  'dialog.attach.supplier': 'Select supplier to be attached',
  'dialog.comment.add': 'Add Note',
  'dialog.comment.edit': 'Edit Note',
  'dialog.comment': 'Note',

  // ---------------------  Filters  -------------------------
  'filters.isActive.true': 'Yes',
  'dialog.isFeatured': 'Featured Partner',
  'dialog.message.isFeatured.true': 'Are you sure you want to add feature?',
  'dialog.message.isFeatured.false': 'Are you sure you want to remove feature?',
  'dialog.title.employee_has_appointment': 'User has an Appointment ',
  'dialog.message.employee_has_appointment': 'Are you sure you want to reassign the User?',
  'dialog.employee_cannot_serve': "User can't serve this Offer",
  'dialog.message.employee_cannot_serve': 'Are you sure you want to reassign the User?',
  'dialog.message.reassign_redirect':
    '{name} is busy with order {orderId}, please select an alternative User for order {orderId}',
  'dialog.cancelOrder': 'Cancel Order ',
  'dialog.message.cancelOrder': 'Are you sure you want to cancel this order?',
  'dialog.103': 'The extended time will exceed the shift',
  'dialog.101': "User can't serve the Offer",
  'dialog.101-103': "User can't serve the Offer and the extended time will exceed the shift",
  'dialog.message.103': 'Are you sure you want to add the service?',
  'dialog.message.101': 'Are you sure you want to add the service?',
  'dialog.message.101-103': 'Are you sure you want to add the service?',
  'dialog.errors.appointment_overlap': "Order can't include this service because the appointments have overlapped",
  'dialog.errors.appointment_overlap2': 'Appointments have an overlap, please check',
  'dialog.errors.connot_update_order': "Order can't be updated",
  'dialog.errors.out': 'The Appointment should be created during Opening Hours',
  'dialog.warnings.exceeds_shift.title': 'The Appointment will exceed the shift',
  'dialog.warnings.exceeds_shift.message': 'Are you sure you want to add this Appointment? (if yes click Save again)',
  'dialog.title.error': 'Error!',
  'dialog.customer.info': 'Customer Info',
  'dialog.contact.person': 'Contact Person Info',
  'dialog.address.map': 'Address Details',
  'dialog.catalog': 'Products Managemant',
  'dialog.address.info': 'Address Info',
  'dialog.address.fullLine': 'Address Full Line',
  'dialog.address.nearBy': 'Near By',
  'dialog.address.villaNumber': 'Villa Number',
  'dialog.address.nearestBuilding': 'Nearest Building',
  'dialog.address.label': 'Name',
  'dialog.locations': 'Locations',
  'dialog.order.details': 'Order Details',
  'dialog.appointment.delete.title': 'Delete Appointment Confirmation',
  'dialog.appointment.delete.msg': 'Are you sure you want to delete the appointment? (if Yes press Save)',
  'filters.isActive.false': 'No',
  'filters.isFeatured.true': 'Yes',
  'filters.isFeatured.false': 'No',

  // ---------------------  Orders  -------------------------

  'appointment.id': 'Id',
  'appointment.order': 'Order Id',
  'appointment.address': 'Address',
  'appointment.customer': 'Customer Name',
  'appointment.employee': 'User Name',
  'appointment.date': 'Date',
  'appointment.status': 'Status',
  'appointment.endTime': 'End Time',
  'appointment.service': 'Services',
  'appointment.startTime': 'Start Time',

  // ---------------------  Reports -------------------------
  'report.startDate': 'Start Date',
  'report.endDate': 'End Date',
  'report.partners': 'Partners',
  'report.customers': 'Customers',
  'report.status': 'Status',
  'report.employees.count': 'User Count',
  'report.orders.count': 'Orders Count',
  'report.invoice.sum': 'Invoice Sum',
  'report.date.error': 'Invalid Date',
  'report.partner.csv': 'Partner Performance.csv',
  'report.clearFilters': 'Clear Filters',
  'report.filters': 'Filters',
  'report.export': 'Export To Excel',
  'customer.name': 'Customer Name',
  'customer.phoneNumber': 'Phone Number',
  'report.employees': 'Users',
  'order.rating': 'Order Rating',
  'employees.stats': 'Users Statistics',
  'services.stats': 'Services Statistics',
  'report.partner.name': 'Name',
  'partner.stats': 'Orders Of Partner Statistics',
  'partner.orders': 'Orders Of Partner',
  'customer.stats': 'Customers Statistics',
  'customer.info': 'Customers Details',

  placed: 'Placed',
  onTheWay: 'On The Way',
  done: 'Done',
  canceled: 'Canceled',

  //---------------  Restaurants -----------
  'restaurants.vat': 'Has Vat?',
  'restaurant.vatValue': 'Vat Value',
  'restaurant.branch.supplier.catalog': 'Branch Supplier Catalog',
  'restaurant.branches.suppliers': "Branch's Suppliers",
  'restaurant.supplier.emailcc': 'Email-CC between {res} Restaurant & {supp} Supplier',
  'restaurant.supplier.emails': 'View Email-CC',
  'restaurant.supplier.emails.emailcc': 'Email-CC',
  'restaurants.empty.suppliers': 'Restaurant has no suppliers yet',
  'restaurants.empty.branches': 'Restaurant has no branches yet',
  'restaurants.details.creationDate': 'Creation date: {date}',
  'restaurants.details.total': 'Total {total}',
  'restaurants.details.kpi': 'Restaurant KPI’s',
  'restaurants.branches': '{count} Branches',
  'restaurants.details': 'Restaurant Details',
  'restaurants.editDetails': 'Edit Details',
  'restaurant.orders.list': "Restaurant's Orders List",
  'restaurant.merge.error.branch': 'LPO branch detail & merged restaurants have a same branch name!',
  'restaurants.assign.update.success': 'Admin info has been updated successfully',
  'restaurants.assign.success': 'Admin assigned successfully',
  'restaurant.branches.name': 'Restaurant -{restaurantName}- Branches',
  'restaurants.select.admin': 'Type user name to get options....',
  'button.submit': 'Submit',
  'restaurant.selectAdmin': 'Select admin',
  'restaurant.selectAdmin.method': 'Select assign method',
  'restaurant.assignAdmin.edit': 'Edit Admin Info',
  'restaurant.merge.notNow': 'Not Now',
  'restaurant.merge.addNew': 'Add New',
  'restaurant.merge.exist': 'Select One',
  'restaurant.assignAdmin.add': 'Assign Admin',
  'restaurant.assign': 'Assign Admin',
  'restaurant.merge.hasUsers': "You can't merge this restaurant because it has many users!",
  'restaurant.merge.error': 'LPO branch detail & merged restaurants must be in the same country!',
  'dialog.restaurant.confirm.merge': 'Merge restaurant!',
  'dialog.message.restaurant.merge': 'Are you sure you want to merge these restaurants?',
  'dialog.mergeRestaurant.note':
    'Please type the restaurant name that you want to merge it with previous LPO branch detail restaurant',
  'restaurant.name': 'Restaurant Name',
  'dialog.restaurant.merge': 'Choose Merged Restaurant',
  'restaurant.merge.choose': 'Choose Merged Restaurant',
  'restaurant.holding': 'LPO branch detail of Restaurant: {holdingRes} - {admin} as admin',
  'restaurant.merging': 'Merged Restaurant: {mergedRes}  - {admin} as admin',
  'restaurant.merge': 'Merge Restaurant',
  'restaurant.supplier.upload': 'Upload Restaurant Branch Supplier Products From CSV File',
  'restaurant.supplier.catalog': 'Restaurant Supplier Products',
  'restaurant.branches.users': 'Branch Name: {branchName}',
  'restaurant.admin.info': 'Admin Info',
  restaurant_admin: 'Restaurant Admin',
  restaurant_manager: 'Restaurant Manager',
  restaurant_user: 'Restaurant User',
  'restaurant.restaurant_admin': 'Admin',
  'restaurant.restaurant_manager': 'Manager',
  'restaurant.restaurant_user': 'User',
  'restaurant.name.en': 'Restaurant Name',
  'restaurant.name.ar': 'Restaurant Name Ar',
  'restaurant.branches': 'Branches',
  'restaurant.users': 'Users',
  'restaurant.form.edit': 'Edit Restaurant',
  'branch.form.edit': 'Edit Branch',
  'branch.name': 'Name',
  'branch.nameEn': 'Name (En)',
  'branch.nameAr': 'Name (Ar)',
  'branch.location': 'Branch Location',
  'user.branches': 'Branches',
  'widgets.view.branches': 'Restaurant Branches',
  'widgets.catalog': 'Products',
  'widgets.merge': 'Merge Supplier',
  'widgets.reject': 'Reject Invitation',
  'widgets.view.users': 'Restaurant Users',
  'widgets.view.branches.users': "Branch's Users",
  'widgets.view.suppliers': 'Restaurant suppliers',
  'widgets.view.kpi': 'View KPIs',
  'user.type': 'Type',
  'invitation.invitedBY': 'Invited By',
  'invitation.status': 'Status',
  'invitation.invoice': 'Invoice',
  'invitation.catalog': 'Products',
  'unit.nameEn': 'Unit Name En',
  'unit.nameAr': 'Unit Name Ar',
  'dialog.unit.delete': 'Delete Unit',
  'dialog.message.unit.delete': 'Are you sure you want to delete this Unit?',
  'button.deleteButton': 'Delete',
  'unit.button.new': 'Create Unit',
  'unit.button.edit': 'Edit Unit',
  'unit.form.new.title': 'New Unit',
  'unit.form.edit.title': 'Edit Unit',
  'global.success.message.catalog': 'Products has been updated successfully',
  'global.success.message.saveAndNotify': 'Invitation has been Approved successfully',
  'catalog.name': 'Name',
  'catalog.nameEn': 'Name(En)',
  'catalog.nameAr': 'Name(Ar)',
  'catalog.category': 'Category',
  'catalog.code': 'Code',
  'catalog.defaultPrice': 'Price',
  'catalog.defaultUnit': 'Unit',
  'catalog.specs': 'Specification',
  'catalog.status': 'Status',
  'catalog.synonyms': 'Synonyms',
  'approve.notify': 'Approve & Notify',
  'reject.invitation': 'Reject Invitation',
  'product.add': 'Add Product',
  'product.edit': 'Edit Product',
  'error.message.notify.rejected': "You can't approve this invitation as it's status is already REJECTED",
  'error.message.notify.approved': "You can't approve this invitation as it's status is already APPROVED",
  'error.message.notify.company': 'required supplier info is required to approve invitation',
  'error.message.notify.employee': 'required user info is required to approve invitation',
  'error.message.reject.rejected': "You can't reject this invitation as it's status is already REJECTED",
  'error.message.reject.approved': "You can't reject this invitation as it's status is already APPROVED",
  'dialog.invitation.reject.pending': 'Reject Invitation!',
  'dialog.message.invitation.reject.pending': 'Are you sure you want to reject this invitation?',
  'dialog.extraInfo.invitation.reject.pending': "You won't be able to change it again.",
  'dialog.invitation.approve.pending': 'Approve Invitation!',
  'dialog.message.invitation.approve.pending': 'Are you sure you want to approve this invitation?',
  'dialog.extraInfo.invitation.approve.pending': "You won't be able to change it again.",
  'button.rejectInvitation': 'Reject',
  'button.approveInvitation': 'Approve',
  'button.attach': 'Attach',
  'dialog.product.delete': 'Delete Product',
  'dialog.message.product.delete': 'Are you sure you want to delete this product?',
  'notification.success.merge': 'Supplier merged Successfully',
  'upload.file': 'Upload File',
  'notification.success.upload': 'File Uploaded Successfully',
  'notification.error.upload.105':
    'Invalid File: data in file are invalid, You can check how data should be structured by downloading Demo file.',
  'notification.error.upload.852': "Invalid File: code shouldn't be empty in csv file.",
  'notification.error.upload.854': "Invalid File: unit shouldn't be empty in csv file.",
  'notification.error.upload.855': 'Invalid File: product name (En or Ar) should be exist in csv file.',
  'notification.error.upload.851': 'CSV file has a duplicated item code!',
  'notification.success.restaurant.supplier.detach': 'Supplier detached successfully',
  'notification.success.restaurant.supplier.attach': 'Supplier attached successfully',
  'notification.success.delete.product': 'Products deleted successfully',
  'notification.error.delete.product':
    "Oops,, it seems that one or more of the selected products can't be deleted due to it's reflection on the system you can instead deactivate it.",
  'catalog.title': 'Products Management',
  'catalog.brand': 'Brand',
  'catalog.brandEn': 'Brand (En)',
  'catalog.brandAr': 'Brand (Ar)',
  'edit.map': 'Edit Location?',
  'download.demo': 'Download demo file?',
  'download.csv': 'Download products as CSV file',
  userInfo: 'User Info',
  'userInfo.name': 'Name',
  'userInfo.email': 'Email',
  'userInfo.phoneNumber': 'PhoneNumber',
  'error.message.catalog.form': 'Product name is required, you should fill either nameEn or nameAr or both',
  note: 'Note: ',
  'note.body': 'One of nameEn and nameAr is required in addition to unit and code.',
  'note.body2': 'One of nameEn and nameAr is required in addition to unit.',

  'global.filter.noMatch': 'NO MATCHING DATA!!',
  'offers.url': 'URL',
  'user.prefrences': 'User Prefrences',
  'user.prefrences.primary': 'Primary',
  'user.prefrences.secondary': 'Secondary',
  'filters.type.restaurant_admin': 'Restaurant Admin',
  'filters.type.restaurant_manager': 'Restaurant Manager',
  'filters.type.restaurant_user': 'Restaurant User',

  // ------------------     Suppliers     ---------------------------
  supplier_admin: 'Supplier Admin',
  supplier_manager: 'Supplier Manager',
  restaurant_warehouse_user: 'Warehouse User',
  supplier_user: 'Supplier User',
  'supplier.form.edit': 'Edit Supplier',
  'supplier.form.add': 'Add Supplier',
  'supplier.error.isPublic': "You can't make this supplier unpublic because he has an active promotion!",
  'company.form.edit': 'Edit Supplier',
  supplierName: 'Supplier Name',
  'supplier.name': 'Supplier Name',
  'company.name': 'Supplier Name',
  'supplier.user.name': 'User Name',
  'supplier.user.email': 'User Email',
  'supplier.user.phoneNumber': 'User Phone Number',
  'supplier.invitedBy': 'Invited By',
  'supplier.supplier_user': 'User',
  'supplier.email': 'Supplier Email',
  'supplier.location': 'Supplier Loaction',
  'supplier.merge': 'Merge Supplier',
  'supplier.tags': 'Tags',
  'supplier.catalog': 'Supplier Products',
  'supplier.upload': 'Upload Supplier Products From CSV File',
  'supplier.info': 'Supplier Info',
  'contact.person': 'Contact Person Info',
  'User.name': 'User Name',
  'employee.form.add': 'Add Email',
  'approve.invitation': 'Approve Invitation',
  'supplier.view.products': 'View Products',
  'supplier.view.sam.mapping': 'SAM’s Mapping',
  'supplier.view.service.region.mapping': 'Service Region Mapping',
  'supplier.view.service.region.configuration': 'Service Region Configuration',
  'supplier.view.processing_time': 'Processing Time',
  'supplier.users': 'Users',
  'restaurant.supplier.detach': 'Detach Supplier',
  'supplier.view.employees': 'Users',
  'suppliers.categories': 'Categories',
  'suppliers.minimumOrder': 'Minimum Order Price',
  'suppliers.onBoardId': 'Onboard ID',
  'suppliers.onboardIdIsUsed': 'Onboard ID is used',
  'suppliers.onboardIdIsInvalid': 'Onboard ID is invalid',
  'suppliers.averageDeliveryDays': 'Average Delivery Days',
  'suppliers.days': 'Days',
  'suppliers.cutOffTime': 'Cut off time',
  'suppliers.cutOffTime.hint': 'Your time will be in UTC!',
  'supplier.type': 'Type',
  'supplier.supplier_admin': 'Admin',
  'supplier.supplier_manager': 'Manager',
  'supplier.view.adminUsers': "Admin's users",
  'supplier.view.managerUsers': "Manager's users",
  'filters.type.supplier_admin': 'Supplier Admin',
  'filters.type.supplier_manager': 'Supplier Manager',
  'filters.type.supplier_user': 'Supplier User',
  'filters.type.restaurant_warehouse_user': 'Warehouse User',
  'dialog.suppliers.merge': 'Merge user to supplier!',
  'button.merge': 'Merge',
  'button.merge.yes': 'Yes, merge',
  'suppliers.merger.success': 'User has been merged successfully',
  'suppliers.error.merge.country': 'Merged user must be in same country of supplier!',
  'dialog.message.suppliers.merge': 'Are you sure you want to merge ({employee}) with ({supplierComp}) supplier ?',
  'dialog.mergeSupplier.note':
    'Merging will unify all the supplier information(Catalog, Promotions, Cut-off time, Minimum order and privacy) !',
  'suppliers.landLineNumber': 'Land Line Number',
  'suppliers.invalid.number': 'Invalid Number!',
  'userInfo.landLineNumber': 'Land Line Number',
  'supplier.isActive': 'Is Active',

  // ------------------     Breadcrumb    ---------------------------
  'edit.breadcrumb': 'Edit',
  'add.breadcrumb': 'Add',
  'users.breadcrumb': 'Users',
  'employees.breadcrumb': 'Users',
  'branchUsers.breadcrumb': "Branch's users",
  'products.breadcrumb': 'Products',
  'branch.breadcrumb': 'Branch',
  'activate.breadcrumb': 'Activate Breadcrumb',
  'approve.breadcrumb': 'Process',
  'supplier.breadcrumb': 'Suppliers',
  'view.breadcrumb': 'View',
  'kpi.breadcrumb': 'KPI',
  'merge.breadcrumb': 'Merge',
  'breadcrumb.branches': 'Branches',
  'breadcrumb.benchmarking': 'Benchmarking',
  'breadcrumb.ticket': 'Ticket Management',
  'branches.breadcrumb': 'Branches',
  'orders-list.breadcrumb': 'Orders List',
  'details.breadcrumb': 'Orders Details',
  'ticket-details.breadcrumb': 'Details',
  'restaurants.details.breadcrumb': 'Restaurant Details',
  'breadcrumb.countries': 'Countries',
  'warehouse.breadcrumb': 'Warehouses',
  //------------------------
  'title.invitationRequests': 'Invitation Requests',
  //--------
  'breadcrumb.profile': 'Profile',
  'breadcrumb.userManagement': 'User Management',
  'breadcrumb.user': 'Users',
  'breadcrumb.employee': 'Users',
  'breadcrumb.restaurants': 'Restaurants',
  'breadcrumb.suppliers': 'Suppliers',
  'breadcrumb.products': 'Products',
  'breadcrumb.elkasoUsers': 'Kaso Users',
  'breadcrumb.invitationRequests': 'Invitation Requests',
  'breadcrumb.orders': 'Orders',
  'breadcrumb.units': 'Units',
  'suppliers.breadcrumb': 'Suppliers',
  'breadcrumb.branch': 'Branches',
  'breadcrumb.warehouse': 'Warehouses',
  'campaigns.breadcrumb': 'Campaign',
  //-----------------orders -------------------------
  'orders.resend': 'Resend email',
  'orders.resend.success': 'Email resent successfully',
  'orders.lpo.download': 'Download LPO',
  'orders.items.total': 'Total (items)',
  'orders.vat': 'VAT',
  'orders.subtotal.price': 'Subtotal',
  'orders.items.price': 'Total(Inc.VAT)({currency})',
  'orders.items.updatedPrice': 'Updated Price ({currency})',
  'orders.supplier': 'Suppliers',
  'orders.restaurant': 'Restaurant',
  'orders.items': 'Items',
  'orders.status': 'Status',
  'orders.ASAP': 'ASAP',
  'orders.note': 'Note',
  'orders.deliveryDate': 'Delivery',
  'item.name': 'Item',
  'item.name.en': 'Product Name En',
  'item.name.ar': 'Product Name Ar',
  'item.price': 'Price',
  'item.quantity': 'QTY',
  'item.approved.quantity': 'Confirmed QTY',
  'item.receivedQuantity': 'Received Quantity',
  'item.receivedPrice': 'Received Price',
  'item.unit': 'Unit',
  'orders.popover.orderItems': 'Ordered Items',
  'orders.popover.restaurant': 'Branches',
  'orders.popover.supplierUser': 'Supplier User',
  'orders.view.employees': 'View Users',
  'orders.view.branches': 'View Branches',
  'orders.asap.true': 'Yes',
  'orders.asap.false': 'No',
  'orders.fullNote': 'Full Note',
  'global.errors.code.unique': 'Sorry, product code is already exist',
  'global.errors.code.file.unique': 'Sorry, products code in csv file should be unique',
  'view.restaurant': 'View Restaurant',
  'view.company': 'View Supplier',
  'view.restaurant.supplier': 'Rest/Supp',
  'products.key': 'Product Name',
  'products.count': 'Count',
  'products.alternatives': 'Alternatives',
  'apply.changes': 'Apply Changes',
  'merge.company.error': 'Please choose supplier to merge with',
  'please.provide.this.employee.email': 'Please provide this user E-mail',
  'error.upload': 'No CSV file selected!!',
  'invitation.summary': 'Invitation Summary',
  'invitation.summary.company': 'Supplier Info',
  'invitation.summary.employee': 'Contact Person Info',
  'invitation.wizard': 'Process',
  'button.next': 'Next',
  'button.back': 'Back',
  'button.approve.invitation': 'Approve Invitation',
  'is.this.an.employee.for': 'Is this an user for:',
  optional: 'Optional',
  'company.info': 'Supplier Info',
  'person.in.contact.info': 'Person In Contact Info',
  'upload.catalog': 'Upload Products',
  'new.company': 'New Supplier',
  'merge.with.an.existing.company': 'Select an existing supplier',
  'is.public': 'Is Public',
  'is.highlighted': 'Starred ? ',
  'add.company': 'Add Supplier',
  'employee.info': 'User Info',
  'create.company': 'Create Supplier',
  'filters.status.draft': 'Draft',
  draft: 'Draft',
  'filters.status.approved': 'Confirmed',
  approved: 'Confirmed',
  'filters.status.canceled': 'Canceled',
  'filters.status.restaurant_placed': 'New Order',
  restaurant_placed: 'New Order',
  draft_expire: 'Expired Draft',
  'filters.status.supplier_placed': 'Supplier Placed',
  'filters.status.restaurant_placed_approval': 'Need Approval',
  supplier_placed: 'Supplier Placed',
  restaurant_placed_approval: 'Need Approval',
  rejected: 'Rejected',
  received: 'Received',
  'filters.isPublic.true': 'Yes',
  'filters.isPublic.false': 'No',
  'filters.isHighlighted.true': 'Yes',
  'filters.isHighlighted.false': 'No',
  true: 'Yes',
  false: 'No',
  'filters.status.pending': 'Pending',
  'filters.status.rejected': 'Rejected',
  'filters.status.received': 'Received',
  'filters.status.draft_expire': 'Expired Draft',
  'filters.status.active': 'Active',
  'filters.status.inactive': 'Inactive',
  'filters.status.null': 'Inactive',
  'filters.status.suggested': 'Suggested',
  'upload.component.text': 'Drag "n" drop CSV file here, or click to select file...only CSV file type is allowed.',
  'orders.code': 'Code',
  'get.master.key': 'Get Master Key',
  'enter.password': 'Enter your password: ',
  'master.key': 'Master Key: ',
  'wrong.password': 'Wrong password',
  'order.created': 'Order Created',
  'order.updated': 'Order Updated',
  'order.placed': 'Order Placed',
  'order.approved': 'Order Confirmed',
  'order.received': 'Order Received',
  'order.canceled': 'Order Canceled',
  'order.rejected': 'Order Rejected',
  'order.draft_expire': 'Order Expired',
  'orders.comments': 'Notes',
  'orders.comment': 'Note',
  'comment.add': 'Add Note',
  'comment.edit': 'Edit Note',
  'orders.creationDate': 'Creation',
  'orders.cutOffTime': 'Cutoff Time',
  'orders.details': 'Order Details',
  'orders.details.order': 'Order #{code}',
  'orders.invoiceNumber': 'Invoice Number',
  'orders.noInvoiceNumber': 'No Invoice Number',
  'orders.details.restaurant': 'Restaurant',
  'orders.details.supplier': 'Supplier',
  'orders.details.delivery': 'Delivery Details',
  'orders.details.deliveryTo': 'Delivery to',
  'orders.details.deliveryTime': 'Expected Delivery Time',
  'orders.details.deliveryInstruction': 'Delivery Instructions',
  'orders.details.noInstruction': 'No Instructions',
  'orders.from': 'From {user}',
  'orders.details.items': 'Order Items',
  'item.priceInv': 'Ind.Price',
  'item.priceTot': 'Tot.Price',
  'comment added': 'Comment Added',
  'comment updated': 'Comment Updated',
  'internal note added': 'Internal Note Added',
  'internal note updated': 'Internal Note Updated',
  'invoice number added': 'Invoice Number Added',
  'invoice number updated': 'Invoice Number Updated',
  'delivery date changed': 'Delivery Date Updated',
  'order updated': 'Order Updated',
  'invoice image added': 'Invoice Image Added',
  'invoice image updated': 'Invoice Image Updated',

  'restaurant.user.delete.error.810': 'Oops.. it seems that you cannot delete this user as he has orders',
  'statistics.restaurantUsers': 'Restaurant Users',
  'statistics.restaurantUsers.help': "Count of restaurant's users",
  'statistics.suppliersUsers': 'Suppliers Users',
  'statistics.suppliersUsers.help': "Count of supplier's users",
  'statistics.placedOrders': 'Placed Orders',
  'statistics.placedOrders.help': 'Count of orders that have been placed',
  'statistics.approvedOrders': 'Approved Orders',
  'statistics.approvedOrders.help': 'Count of orders that have been approved',
  'statistics.avgBasketSize': 'Avg Basket Size',
  'statistics.avgBasketSize.help': 'Average items count for orders',
  'statistics.avgSpendingsPerOrder': 'Avg Spending Per Order',
  'statistics.avgSpendingsPerOrder.help': 'Average spending Per orders',
  'statistics.totalProducts': 'Total Products',
  'statistics.totalProducts.help': 'Count of active products',
  'kpi.requestedOrders': 'Requested Orders',
  'kpi.requestedOrders.help': 'Count of orders that have been requested',
  'kpi.approvedOrders': 'Approved Orders',
  'kpi.approvedOrders.help': 'Count of orders that have been approved',
  'kpi.canceledOrders': 'Canceled Orders',
  'kpi.canceledOrders.help': 'Count of orders that have been canceled',
  'kpi.approvedOrdersValue': 'Spending',
  'kpi.approvedOrdersValue.help': 'Approved orders value',
  'kpi.avgBasketSize': 'Avg Basket Size',
  'kpi.avgBasketSize.help': 'Average items count for orders',
  'kpi.avgSpendingsPerOrder': 'Avg Spending Per Order',
  'kpi.avgSpendingsPerOrder.help': 'Average spending per order',
  'kpi.lastOrderDate': 'Last Order Date',
  'kpi.lastOrderDate.help': 'Last order date',
  'kpi.restaurantsCount': 'Restaurants Count',
  'kpi.restaurantsCount.help': 'Count of restaurants that have been dealt with',
  'kpi.avgDeliveryTime': 'Avg Delivery Date',
  'kpi.avgDeliveryTime.help': 'Average delivery time for orders in days',
  'kpi.totalProducts': 'Total Products',
  'kpi.totalProducts.help': 'Count of active products',
  'kpi.restaurant': 'Restaurant kpi',
  'kpi.supplier': 'Supplier kpi',
  'dialog.attachSupplier.note': 'Note: Suppliers in the same country of the restaurant will be listed',
  'statistics.date': 'Date',
  'statistics.startDate': 'Start Date',
  'statistics.endDate': 'End Date',
  'kpi.date.error': 'Start date must be the smallest!',
  'kpi.endDate.error': 'End date must be the biggest!',

  //----------------- Promotions -------------------------
  'breadcrumb.promotions': 'Promotions',
  'promotions.form.new.title': 'Add Promotion',
  'promotions.form.edit.title': 'Edit Promotion',
  'promotions.form.new': 'Add Promotion',
  'promotions.form.edit': 'Edit Promotion',
  'promotions.summary': 'Summary',
  'promotions.titleEn': 'Title (En)',
  'promotions.titleAr': 'Title (Ar)',
  'promotions.descEn': 'Description (En)',
  'promotions.descAr': 'Description (Ar)',
  'promotions.summaryEn': 'Summary (En)',
  'promotions.summaryAr': 'Summary (Ar)',
  'promotions.supplier': 'Supplier',
  'promotions.expiryDate': 'Expiry Date',
  'promotions.expired': 'Expired Date!',
  'dialog.promotions.info.expiryDate': "You can't activate this promotion till update the expiry date of it!",
  'dialog.promotions.isActive.true': 'Activate promotion!',
  'dialog.message.promotions.isActive.true': 'Are you sure you want to activate this promotion?',
  'dialog.promotions.isActive.false': 'Deactivate promotion!',
  'dialog.message.promotions.isActive.false': 'Are you sure you want to deactivate this promotion?',
  'button.global.actions.update': 'Update',
  'promotions.isActive.true': 'Activate promotion!',
  'promotions.supplier.info': 'Supplier Info',
  'promotions.supplier.name': 'Name',
  'promotions.supplier.email': 'Email',
  'promotions.button.new': 'Add promotion',
  'promotions.button.edit': 'Edit promotion',

  //----------------- Categories -------------------------
  'breadcrumb.categories': 'Categories',
  'categories.form.new.title': 'Add Category',
  'categories.form.edit.title': 'Edit Category',
  'categories.form.new': 'Add Category',
  'categories.form.edit': 'Edit Category',
  'categories.title': 'Title',
  'categories.titleEn': 'Title (En)',
  'categories.titleAr': 'Title (Ar)',
  'dialog.categories.isActive.true': 'Activate category!',
  'dialog.message.categories.isActive.true': 'Are you sure you want to activate this category?',
  'dialog.categories.isActive.false': 'Deactivate category!',
  'dialog.message.categories.isActive.false': 'Are you sure you want to deactivate this category?',
  'categories.isActive.true': 'Activate category!',
  'categories.button.new': 'Add category',
  'categories.button.edit': 'Edit category',
  'categories.error.deactivate': "This category related to supplier! you can't deactivate it!",

  //--------------------------- Preferences ----------------------------
  'permissions.restaurants': 'Restaurant',
  'permissions.branches': 'Branch',
  'permissions.clearFilters': 'Clear',
  'permissions.noData': 'No data to view',
  'permissions.viewDetails': 'To get data please change the previous filters',
  'permissions.orders': 'Require approval',
  'permissions.chats': 'Chat visibility',
  'permissions.change.preference.success': 'Preference has been changed successfully',
  'permissions.type': 'Preference',
  'dialog.permissions.false': 'Deactivate preference!',
  'dialog.message.permissions.false': 'Are you sure you want to deactivate this preference?',
  'dialog.permissions.true': 'Activate preference!',
  'dialog.message.permissions.true': 'Are you sure you want to activate this preference?',
  'permissions.permissions.entityChats.false': 'No',
  'permissions.permissions.entityChats.true': 'Yes',
  'permissions.permissions.ordersApproval.false': 'No',
  'permissions.permissions.ordersApproval.true': 'Yes',
  'permissions.adminRights': 'Invite user',
  'permissions.isConfirmed': 'Show Market place',
  'permissions.isConfirmed.false': 'No',
  'permissions.isConfirmed.true': 'Yes',
  'dialog.permissions.isConfirmed.false': 'Hide market place!',
  'dialog.message.permissions.isConfirmed.false': 'Are you sure you want to change status to hidden?',
  'dialog.permissions.isConfirmed.true': 'Show market place!',
  'dialog.message.permissions.isConfirmed.true': 'Are you sure you want to change status to not hidden?',
  'dialog.extraInfo.permissions.isConfirmed.false':
    "Note that please this user won't be able to place order from any unlinked public supplier!!",
  'dialog.extraInfo.permissions.isConfirmed.true':
    'Note that please this user will be able to place order from any unlinked public supplier!!',
  'permissions.canEditCatalog': 'Edit Catalog',

  //------------------ OTP ----------------------
  'otp.phoneNumber': 'Phone Number',
  'otp.reference': 'Reference',
  'otp.code': 'OTP Code',
  'otp.status': 'Status',
  'otp.pending': 'Pending',
  'otp.done': 'Done',
  'otp.pending.hint': "User still didn't use OTP code!!",
  'otp.done.hint': 'User used OTP code',
  'otp.type.sms': 'SMS',
  'otp.type.email': 'EMAIL',
  'otp.type.whatsapp': 'WhatsApp',
  'otp.type': 'Type',
  'otp.status.pending': 'Pending',
  'otp.status.expired': 'Expired',
  'otp.status.done': 'Done',

  //------------------ Whats Numbers ----------------------
  'whats.user': 'Supplier',
  'whats.active': 'Send Messages',
  'whats.phoneNumber': 'Phone Number',
  'dialog.whats.isActive.false': 'Deactivate sending whats messages!',
  'dialog.message.whats.isActive.false': 'Are you sure you want to deactivate sending whats messages to this number?',
  'dialog.whats.isActive.true': 'Activate sending whats messages!',
  'dialog.message.whats.isActive.true': 'Are you sure you want to activate sending whats messages to this number?',

  //------------------  Countries ----------------------
  countries: 'Countries',
  'countries.name': 'Name',
  'countries.form.new.title': 'Add country',
  'countries.form.edit.title': 'Edit country',
  'countries.form.view.title': 'View country',
  'countries.location': 'Location',
  'countries.startTime': 'Start cut off time',
  'countries.endTime': 'End cut off time',
  'countries.key': 'Starting#',
  'countries.currency': 'Currency',
  'countries.currencyEn': 'Currency(En)',
  'countries.currencyAr': 'Currency(Ar)',
  'countries.flag': 'Flag',
  'countries.location.required': 'Location is required!',
  'dialog.countries.isActive.false': 'Deactivate country!',
  'dialog.countries.isActive.true': 'Activate country!',
  'dialog.message.countries.isActive.false': 'Are you sure you want to deactivate this country?',
  'dialog.message.countries.isActive.true': 'Are you sure you want to activate this country?',

  //------------------  Restaurant Transfers ----------------------
  'breadcrumb.restaurant-transfers': 'Restaurant Transfers',
  'transfer-details.breadcrumb': 'Transfer Details',
};
