import {
  Button,
  ButtonProps,
  FullPageSpinner,
  HStack,
  StackProps,
  Text,
  Tooltip,
  VStack,
  WhiteCard,
} from '@elkaso-app/web-design';
import { useDownloadLpoApi } from 'apis/restaurant-transfers/use-download-lpo-api';
import { useGetRestaurantTransferDetailsApi } from 'apis/restaurant-transfers/use-get-restaurant-transfer-details-api';
import { useParams } from 'react-router-dom';

interface ISectionHeader extends StackProps {
  sectionName: string;
  buttonArray?: ButtonProps[];
}

const SectionHeader = ({ sectionName, buttonArray, ...rest }: ISectionHeader) => {
  return (
    <HStack spacing='sm' pb='md' mb='md' borderBottom='4px solid' borderColor='gray.100' {...rest}>
      <Text fontSize='xl' fontWeight='bold' me='auto' textTransform='capitalize'>
        {sectionName}
      </Text>

      {buttonArray?.map(({ children, title, ...props }, i) => {
        if (title) {
          return (
            <Tooltip key={i} label={title}>
              <Button {...props}>{children}</Button>
            </Tooltip>
          );
        }

        return (
          <Button key={i} {...props}>
            {children}
          </Button>
        );
      })}
    </HStack>
  );
};

export const ProductDetailsScreen = () => {
  const { code } = useParams<{ code: string }>();

  const { isLoading, isFetching, data: transferData } = useGetRestaurantTransferDetailsApi(code);

  const {
    isLoading: isLoadingDownloadLpo,
    isFetching: isFetchingDownloadLpo,
    refetch: downloadLpoApi,
  } = useDownloadLpoApi(transferData?.id, transferData?.code);

  if (isLoading || isFetching) return <FullPageSpinner />;

  return (
    <>
      <HStack spacing='md' align='stretch' h='full'>
        <VStack flex={1} align='stretch' spacing='md'>
          <WhiteCard p='md' flex={1} display='flex' flexDir='column'>
            <SectionHeader sectionName='Transfer Details' buttonArray={[]} mb='0' />
          </WhiteCard>

          <WhiteCard p='md'>
            <SectionHeader sectionName='Transfer Comments' />
          </WhiteCard>
        </VStack>

        <WhiteCard p='md' flex={-1} flexDir='column' display='flex'>
          <SectionHeader
            sectionName='Transfer Items'
            buttonArray={[
              {
                children: 'Download LPO',
                variant: 'solid',
                colorScheme: 'red',
                onClick: () => downloadLpoApi(),
                isLoading: isLoadingDownloadLpo || isFetchingDownloadLpo,
              },
            ]}
          />
        </WhiteCard>
      </HStack>
    </>
  );
};
