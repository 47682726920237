import { BasePagination, formatPrice } from '@elkaso-app/web-design';
import { ProductData, useGetProductsApi } from 'apis/products/use-get-products-api';
import { BaseSortButton, BaseTable, BaseTableContainer, SupplierIdFilterButton } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import React, { useEffect } from 'react';
import { CellProps } from 'react-table';
import { changeTitle } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { ActionsCell, ProductActionsCell } from '../components/actions-cell';
import { SupplierInfoCell, SupplierInfoCellProps } from '../components/supplier-info-cell';

export const ProductsListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const dispatch = useAppDispatch();
  const { isLoading, isFetching, data } = useGetProductsApi();
  const { pageCount, total, data: productsData } = data ?? {};

  useEffect(() => {
    dispatch(changeTitle('sidebar.products'));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHeader name='Supplier' filterButtonComponent={<SupplierIdFilterButton />} />,
        accessor: 'supplier',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['supplier']>) => (
          <CustomTableCell<SupplierInfoCellProps>
            as={SupplierInfoCell}
            supplierId={value.supplierId}
            name={value.name}
          />
        ),
      },
      {
        Header: <TableHeader name='Name' />,
        accessor: 'nameEn',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['nameEn']>) => (
          <TableCell value={value} color='black' fontWeight='normal' />
        ),
      },
      {
        Header: <TableHeader name='Code' />,
        accessor: 'code',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['code']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Price' />,
        accessor: 'price',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['price']>) => <TableCell value={formatPrice(value)} />,
      },
      {
        Header: <TableHeader name='Unit' />,
        accessor: 'unit',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['unit']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Duplications' sortButtonComponent={<BaseSortButton sortBy='duplications' />} />,
        accessor: 'duplications',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['duplications']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Orders' />,
        accessor: 'ordersCount',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['ordersCount']>) => (
          <TableCell value={value} fontWeight='semibold' color='gray.600' />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<ProductActionsCell> as={ActionsCell} product={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    nameEn: string | null;
    code: string | null;
    unit: string | null;
    price: number;
    supplier: {
      supplierId: number;
      name: string;
    };
    ids: number[];
    duplications: number;
    ordersCount: number;
    actions: ProductData;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      productsData?.map((item: ProductData): IColumnsAccessorData => {
        return {
          id: item?.ids?.[0],
          nameEn: item?.nameEn || null,
          code: item?.code || null,
          unit: item?.defaultUnit || null,
          price: item?.defaultPrice,
          supplier: {
            supplierId: item?.supplier?.id,
            name: item?.supplier?.name,
          },
          ids: item?.ids || [],
          duplications: item?.duplications,
          ordersCount: item?.ordersCount,
          actions: item,
        };
      }),
    [productsData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title='sidebar.products' />
          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
