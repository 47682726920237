import { HStack } from '@elkaso-app/web-design';
import { ProductData } from '../../../apis/products/use-get-products-api';

export interface ProductActionsCell {
  product: ProductData;
}

export const ActionsCell = ({ product }: ProductActionsCell) => {
  return <HStack>{product.ids.toString()}</HStack>;
};
